import Button from '@components/DesignLibrary/Button';
import Modal from '@components/Modal';
import Spacer from '@components/Spacer';
import Check from '@/components/Icons/Check';
import X from '@/components/Icons/X';
import NoodleAI from '@/components/Icons/NoodleAI';

import * as tsClient from '@tsClient';
import { compact, noop } from 'lodash';
import { ApprovedBy, DocumentAnalysisRaw, OldApprovedBy } from '@/typings/document-api-models';
import { isNoodler } from '@/providers/FeatureFlags/checkFeatureFlag';
import { useUserProfile } from '@/providers/Auth';
import useNoodleApi from '@/hooks/useNoodleApi';
import CustomSelect from '@/components/FormFields/CustomSelect';
import PrettyJson from '../DocumentPreview/PrettyJson';
import { ApprovedByCaseWorker, ApprovedByNoodleAi, NeedsReview } from './ValidationStatus';
import s from './DocumentStatus.module.scss';

export type OldDocumentAnalysis = {
  isBlurry: string;
  isExpired: string;
  matchesDocumentDescription: string;
};
export type NewDocumentAnalysis = {
  isBlurry: boolean;
  isExpired: boolean;
  matchesDocumentDescription: boolean;
  matchesDocumentDescriptionFailReason?: string | null;
};
export type DocumentAnalysis =
  | {
      summary: string | null;
      documentAnalysis?: OldDocumentAnalysis | NewDocumentAnalysis | null;
    }
  | null
  | undefined;

type Props = {
  documentAnalysis: DocumentAnalysis;
  onClose: () => void;
  status: string;
  approvedBy: ApprovedBy | OldApprovedBy | null | undefined;
  isRollUp: boolean;
  onClick?: () => void;
  documentId?: string;
  fileId?: string;
  documentAnalysisRaw?: DocumentAnalysisRaw;
};

const DocumentAnalysisModal: React.FC<Props> = ({
  documentAnalysis,
  onClose,
  status,
  approvedBy: approvedByProp,
  isRollUp,
  onClick = noop,
  documentId,
  fileId,
  documentAnalysisRaw,
}) => {
  const approvedBy = typeof approvedByProp === 'string' ? approvedByProp : approvedByProp?.name;
  const profile = useUserProfile();
  const summary = documentAnalysis?.summary;
  const innerDocumentAnalysis = documentAnalysis?.documentAnalysis;
  const {
    isBlurry: isBlurryString,
    isExpired: isExpiredString,
    matchesDocumentDescription: matchesDocumentDescriptionString,
  } = innerDocumentAnalysis || {};
  const isBlurry = isBlurryString === 'true' || isBlurryString === true;
  const isExpired = isExpiredString === 'true' || isExpiredString === true;
  const matchesDocumentDescription = matchesDocumentDescriptionString === 'true' || matchesDocumentDescriptionString === true;

  const { getData: updateDocumentValidation } = useNoodleApi(tsClient.documents.updateDocumentValidationById, { toastOnError: true });
  const { getData: updateDocumentFileValidation } = useNoodleApi(tsClient.documents.updateDocumentFileValidationById, { toastOnError: true });

  const generateSummaryForRollUp = (): string => {
    if (!isBlurry && !isExpired && matchesDocumentDescription) {
      return 'All documents pass. The images are clear and legible, not expired, and matches description.';
    }

    return 'Some documents failed the document validation.';
  };

  const generateSummaryForSingleUpload = (): string => {
    if (!isBlurry && !isExpired && matchesDocumentDescription) {
      return 'The image is clear and legible, not expired, and matches description.';
    }

    return 'The document has failed one or more validations.';
  };

  const generateSummary = (isRollUpSummary: boolean): string => {
    if (!isRollUpSummary) {
      return generateSummaryForSingleUpload();
    }

    return generateSummaryForRollUp();
  };

  const shouldDisplaysummary = !isBlurry && !isExpired && matchesDocumentDescription;

  return (
    <Modal hideBottomInset title="Noodle AI Document Review" onClose={onClose} >
      <div className={s.container}>
        <Spacer size={12} />
        <CustomSelect
          id="document-validation-status-dropdown"
          label="Document status"
          options={compact([
            // UX Note: In the case where `Noodle Ai` has approved,
            // we want to show it in a way that's distinct from `Approved` manually by
            // you or a teammate. Thus we will show it as a option
            // However if the status is not approved by `Noodle Ai`,
            // Then we don't even want to show it in the list because it's never manually selectable
            approvedBy === 'Noodle Ai'
              ? {
                disabled: true,
                title: <ApprovedByNoodleAi />,
                value: 'ApprovedByNoodleAi',
              }
              : null,
            {
              title: <ApprovedByCaseWorker approvedBy={null} />,
              value: 'Approved',
            },
            {
              title: <NeedsReview />,
              value: 'Needs Review',
            },
          ])}
          onChange={value => {
            if (value === 'Approved') {
              if (documentId) {
                updateDocumentValidation({
                  documentId,
                  statusOfValidationStatus: 'Approved',
                });
              }

              if (fileId) {
                updateDocumentFileValidation({
                  fileId,
                  statusOfValidationStatus: 'Approved',
                });
              }
            }

            if (value === 'Needs Review') {
              if (documentId) {
                updateDocumentValidation({
                  documentId,
                  statusOfValidationStatus: 'Needs Review',
                });
              }

              if (fileId) {
                updateDocumentFileValidation({
                  fileId,
                  statusOfValidationStatus: 'Needs Review',
                });
              }
            }
          }}
          value={
            // UX Note: we want to discern between approved manually and approved by Noodle Ai
            approvedBy === 'Noodle Ai' ? 'ApprovedByNoodleAi' : status
          }
        />
        <Spacer size={16} />
        <p className={s.summaryTitle}>
          <NoodleAI size={16} />
          Noodle Ai document summary:
        </p>
        <Spacer size={8} />
        <p className="body-sm">
          {shouldDisplaysummary && summary ? `${summary} ` : ''}
          {generateSummary(isRollUp)} {(innerDocumentAnalysis as NewDocumentAnalysis)?.matchesDocumentDescriptionFailReason}
        </p>
        <Spacer size={8} />
        <ul className={s.summary}>
          <li>
            {matchesDocumentDescription ? <Check size={16} color="#00A91C" /> : <X size={16} color="#CC1426" />}
            <p className="caption">{isRollUp ? 'Documents match the requirements' : 'Document matching the requirements'}</p>
          </li>
          <li>
            {!isBlurry ? <Check size={16} color="#00A91C" /> : <X size={16} color="#CC1426" />}
            <p className="caption">{isRollUp ? 'Documents are not blurry' : 'Document is not blurry'}</p>
          </li>
          <li>
            {!isExpired ? <Check size={16} color="#00A91C" /> : <X size={16} color="#CC1426" />}
            <p className="caption">{isRollUp ? 'Documents are not expired' : 'Document is not expired'}</p>
          </li>

          {documentAnalysisRaw && isNoodler(profile) && (
            <>
              <Spacer size={12} />
              <PrettyJson stringifiedJson={JSON.stringify(documentAnalysisRaw)} />
            </>
          )}
        </ul>
        <Spacer size={12} />
        {isRollUp && (
          <Button
            variant="primary"
            size="md"
            onClick={() => {
              onClick();
            }}
            fullWidth
            type="button"
          >
            Open Documents
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default DocumentAnalysisModal;
