import { useQueryState } from 'nuqs';

export const QS_KEY = 'userWorkflowDocumentId';

// @todo - figure out how to move the stuff in Modals.tsx's useEffect into this file somehow...

export const useOpenUserWorkflowDocumentPane = (): ((args: { userWorkflowDocumentId: string }) => void) => {
  const [_userWorkflowDocumentId, setUserWorkflowDocumentId] = useQueryState(QS_KEY, { history: 'push' });

  return ({ userWorkflowDocumentId }) => setUserWorkflowDocumentId(userWorkflowDocumentId);
};
