import CircularProgress from '@components/CircularProgress';
import Button from '@components/DesignLibrary/Button';
import * as format from '@format';
import s from './WidgetProgress.module.scss';

const WidgetProgress: React.FC<{
  title: string;
  type?: string;
  progress: {
    completed: number;
    total: number;
  };
  action?: {
    label: string;
    onClick: () => void;
  } | null;
}> = ({ type, title, progress, action }) => (
  <div className={s.progress}>
    <CircularProgress size={24} progress={format.percentageComplete({ ...progress })} />
    <div>
      <p className="body-sm-bold">{title}</p>
      <p className="caption">
        {progress.completed} of {progress.total}{type ? ` ${type}` : ''} completed
      </p>
    </div>
    {action && (
      <Button size="sm" variant="primary" onClick={action.onClick}>
        {action.label}
      </Button>
    )}
  </div>
);

export default WidgetProgress;
