export type Scalars = {
  DateTime: string; // Date on BE, string on FE
};

export enum FollowupCadenceUnits {
  Minutes = 'minutes',
  Hours = 'hours',
  Days = 'days',
  Weeks = 'weeks',
}

export type DocumentRequest = {
  id: string;
  creatorId: string;
  title: string;
  isFollowupEnabled: boolean;
  isArchived: boolean;
  autoAssignCreatorInWorkflows: boolean;
  autoAssignCustomerInWorkflows: boolean;
  followupCadence: number;
  followupCadenceUnits: FollowupCadenceUnits;
  isForCreator: boolean;
};

export type DocumentRequestFile = {
  id: string;
  documentRequestId: string;
  fileName: string;
  fileDescription: string | null;
  isRequired: boolean;
  isForYourEyesOnly: boolean;
  order: number;
};

export enum DocumentRequestStatus {
  SUCCEEDED = 'succeeded',
  IN_PROGRESS = 'in-progress',
  IN_REVIEW = 'in-review',
  FAILED = 'failed',
}

export enum DocumentRequestFileStatus {
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete',
  ACTION_REQUIRED = 'action-required',
}

export type DocumentRequestUser = {
  id: string;
  documentRequestId: string;
  personId: string;
  creatorId: string;
  status: DocumentRequestStatus;
  actionRequiredFiles: number;
  hasPermission: boolean;
  isCollaborator: boolean;
  uploadedRequiredFiles: number;
  totalRequiredFiles: number;
  uploadedFiles: number;
  totalFiles: number;
  userWorkflowId: string | null;
  createdAt: Scalars['DateTime'];
};

export type DocumentRequestUserPermissions = {
  id: string;
  documentRequestId: string;
  personId: string;
};

type OldDocumentAnalysis = {
  isBlurry: string;
  isExpired: string;
  matchesDocumentDescription: string;
};
type NewDocumentAnalysis = {
  isBlurry: boolean;
  isExpired: boolean;
  matchesDocumentDescription: boolean;
};
export type DocumentAnalysisRaw = Record<string, unknown> | null;
export type DocumentRequestUserFileDocument = {
  documentRequestUserFileId: string;
  documentId: string;
  documentAnalysis: {
    documentAnalysis: OldDocumentAnalysis | NewDocumentAnalysis | null;
    summary: string | null;
  };
  documentAnalysisRaw: DocumentAnalysisRaw;
  validationStatus: { status: string; approvedBy: string | null } | null;
};

export type OldApprovedBy = string | null; // We'll migrate off this soon
export type ApprovedBy = { id: string; name: string | null };
export type ValidationStatus = { status: string; approvedBy: OldApprovedBy | ApprovedBy } | null;
export type DocumentRequestUserFile = {
  id: string;
  documentRequestUserId: string;
  documentRequestFileId: string | null;
  documentId: string | null;
  personId: string;
  fileName: string | null;
  fileDescription: string | null;
  isRequired: boolean;
  isForYourEyesOnly: boolean | null;
  status: DocumentRequestFileStatus;
  rejectionMessage: string | null;
  creatorId: string;
  hasPermission: boolean;
  order: number;
  validationStatus: ValidationStatus;
};

export enum DocumentStatus {
  UPLOADED = 'uploaded',
  IN_PROGRESS = 'in-progress',
  FAILED = 'failed',
}

export type Document = {
  createdAt: Scalars['DateTime'];
  creatorId: string | null;
  fileName: string;
  fileSize: number;
  hasContentPermission: boolean;
  id: string;
  isCollaborator: boolean;
  mimeType: string;
  personId: string;
  status: DocumentStatus;
  uploadedAt: Scalars['DateTime'];
  uploadedBy: {
    name: string | null;
  };
};
