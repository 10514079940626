import { useQueryState } from 'nuqs';

export const QS_KEY = 'paneFormRequestId';

// @todo - figure out how to move the stuff in Panes.tsx's useEffect into this file somehow...

export const useOpenFormRequestPane = (): ((args: { formRequestId: string }) => void) => {
  const [_formRequestId, setFormRequestId] = useQueryState(QS_KEY, { history: 'push' });

  return ({ formRequestId }) => setFormRequestId(formRequestId);
};
