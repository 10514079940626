import React, { useEffect, useMemo } from 'react';
import WarningCircle from '@components/Icons/WarningCircle';
import { CheckCircle } from '@phosphor-icons/react';
import NoodleAI from '@/components/Icons/NoodleAI';
import useNoodleApi from '@/hooks/useNoodleApi';
import getPersonById from '@/tsClient/getPersonById';
import Progress from '@/components/DesignLibrary/Button/Progress';
import { ApprovedBy, OldApprovedBy } from '@/typings/document-api-models';
import s from './DocumentStatus.module.scss';

const NoodleEvaluating: React.FC = () => (
  <p className={s.validationStatus}>
    <Progress size="sm" />
    <span
      style={{
        color: 'var(--color-gray-75)',
      }}
    >
      Noodle Ai evaluating this document...
    </span>
  </p>
);

export const NeedsReview: React.FC = () => (
  <p className={s.validationStatus}>
    <WarningCircle size={16} weight="fill" color="var(--color-warning)" />
    <span>Needs Review</span>
  </p>
);

export const ApprovedByNoodleAi: React.FC = () => (
  <p className={s.validationStatus}>
    <NoodleAI size={16} />
    <span>
      <span style={{ color: 'var(--color-gray-75)' }}>Approved by</span>
      {" "}Noodle Ai
    </span>
  </p>
);

export const ApprovedByCaseWorker: React.FC<{ approvedBy: string | null }> = ({ approvedBy }) => (
  <p className={s.validationStatus}>
    <CheckCircle size={16} weight="fill" color="var(--color-success)" />
    <span>
      <span style={approvedBy ? { color: 'var(--color-gray-75)' } : undefined}>{approvedBy ? 'Approved by' : 'Approved'}</span>
      {approvedBy && <span>{" "}{approvedBy}</span>}
    </span>
  </p>
);

type OldProps = { status: string; approvedBy: OldApprovedBy | null };
type NewProps = { status: string; approvedBy: ApprovedBy | null };
type Props = { status: string; approvedBy: ApprovedBy | OldApprovedBy | null };

const OldValidationStatus: React.FC<OldProps> = ({ status, approvedBy }) => {
  const { data: personData, getData: getPersonFn } = useNoodleApi(getPersonById);
  const overrideApprovedByName = useMemo(() => {
    if (!personData) {
      return 'Teammate';
    }

    return personData.name || 'Teammate';
  }, [personData]);

  useEffect(() => {
    if (approvedBy === 'Noodle Ai' || !approvedBy) return;

    getPersonFn({ id: approvedBy });
  }, [approvedBy, getPersonFn]);

  return (
    <>
      {status === 'Needs Review' && <NeedsReview />}
      {status === 'Approved' && approvedBy === 'Noodle Ai' && <ApprovedByNoodleAi />}
      {status === 'Approved' && approvedBy !== 'Noodle Ai' && <ApprovedByCaseWorker approvedBy={overrideApprovedByName} />}
      {status === 'Evaluating' && <NoodleEvaluating />}
    </>
  );
};

const NewValidationStatus: React.FC<NewProps> = ({ status, approvedBy }) => {
  const name = approvedBy?.name || '';

  return (
    <>
      {status === 'Needs Review' && <NeedsReview />}
      {status === 'Approved' && name === 'Noodle Ai' && <ApprovedByNoodleAi />}
      {status === 'Approved' && name !== 'Noodle Ai' && <ApprovedByCaseWorker approvedBy={name} />}
      {status === 'Evaluating' && <NoodleEvaluating />}
    </>
  );
};

const ValidationStatus: React.FC<Props> = ({ status, approvedBy }) => {
  if (typeof approvedBy === 'string') {
    return <OldValidationStatus status={status} approvedBy={approvedBy} />;
  }

  return <NewValidationStatus status={status} approvedBy={approvedBy} />;
};

export default ValidationStatus;
