import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Buttons from '@components/Buttons/Buttons';
import * as ApiModels from '@typings/document-api-models';
import DocumentUploader, { DocumentUploaderRef } from '@components/Document/DocumentUploader';
import { logError } from '@providers/ErrorTracking';
import CircularProgress from '@components/CircularProgress';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import File from '@components/DesignLibrary/File';
import UserProfileContext from '@layouts/UserProfileLayout/UserProfileContext';
import { useOpenDocumentRequestUserFilePane } from '@panes/hooks';
import { useRouter } from 'next/router';
import { getNoodleDocumentsUrl, getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import dashboardContext from '@layouts/DashboardLayout/DashboardContext';
import SecondaryActions from '@components/DesignLibrary/SecondaryActions';
import CollapsibleArea from '@components/CollapsibleArea';
import Lock from '@components/Icons/Lock';
import BannerStatus from '@components/DesignLibrary/BannerStatus';
import { debounce } from 'lodash';
import DOMPurify from 'isomorphic-dompurify';
import convertLinksToAnchors from '@/helpers/convertLinksToAnchors';
import { DocumentAccessContext } from '@providers/DocumentAccess';
import DocumentAccessModal from '@modals/DocumentAccessModal';
import s from './DocumentFileCard.module.scss';
import CardLabel from './CardLabel';
import ValidationStatusButton from '../DocumentStatus/ValidationStatusButton';

type Props =
  | {
      creatorSlug: string;
      creatorName?: string | null;
      documentRequestUserFileId: string;
      documentRequestUserId: string;
      fileDescription: string | null;
      fileName: string;
      hasPermission: boolean;
      isForYourEyesOnly: boolean;
      isEdit?: false;
      isRequired: boolean;
      onFinishUpload?: (args: {
        document: Parameters<NonNullable<Parameters<typeof DocumentUploader>[0]['onFinishUpload']>>[0];
        documentRequestUserFileId: string;
      }) => Promise<void> | void;
      refetchFiles: () => Promise<void>;
      canDelete: boolean;
      canUpdateIsRequired: boolean;
      documents: Pick<ApiModels.Document, 'id' | 'fileName' | 'createdAt' | 'uploadedBy' | 'mimeType' | 'uploadedAt'>[];
      actionRequired?: boolean;
      showDocumentValidationStatus?: boolean;
    }
  | {
      creatorSlug?: never;
      creatorName?: never;
      documentId?: never;
      documentRequestUserFileId?: never;
      documentRequestUserId?: never;
      fileDescription: string | null;
      fileName: string;
      hasPermission?: never;
      isForYourEyesOnly?: never;
      fileSize?: number | null;
      isEdit: true;
      isRequired: boolean;
      onFinishUpload?: never;
      uploadedAt?: string | null;
      uploadedBy?: string | null;
      refetchFiles?: never;
      canDelete?: never;
      canUpdateIsRequired?: never;
      documents?: never;
      actionRequired?: never;
      showDocumentValidationStatus?: boolean;
    };

const DocumentFileCard: FC<Props> = ({
  creatorSlug,
  creatorName,
  documentRequestUserFileId,
  documentRequestUserId,
  fileDescription,
  fileName,
  hasPermission,
  isEdit = false,
  isRequired,
  onFinishUpload,
  isForYourEyesOnly,
  refetchFiles,
  canDelete,
  canUpdateIsRequired,
  documents,
  actionRequired,
  showDocumentValidationStatus = false,
}) => {
  const router = useRouter();
  const { isInDashboard } = useContext(dashboardContext);
  const { accessToken } = useContext(DocumentAccessContext);
  const { isInUserProfile } = useContext(UserProfileContext);
  const [uploadError, setUploadError] = useState<Error | null>(null);
  const [uploadingProgress, setUploadingProgress] = useState<number>(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdatingIsRequired, setIsUpdatingIsRequired] = useState(false);
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const openDocumentRequestUserFilePane = useOpenDocumentRequestUserFilePane();
  const documentUploaderRef = useRef<DocumentUploaderRef>(null);
  const [lastFileUploadedAt, setLastFileUploadedAt] = useState<Date | null>(null);
  const [isAccessModalOpen, setIsAccessModalOpen] = useState(false);

  const { getData: deleteFn } = useNoodleApi(tsClient.documentRequests.deleteDocumentRequestUserFile);
  const { getData: updateFn } = useNoodleApi(tsClient.documentRequests.updateDocumentRequestUserFile);

  const {
    data: validationData,
    getData: getDocumentFileValidation,
  } = useNoodleApi(tsClient.documents.getDocumentFileValidationById);
  const [lastRetrievedValidationData, setLastRetrievedValidationData] = useState(validationData);

  useEffect(() => {
    if (!validationData) return;

    setLastRetrievedValidationData(validationData);
  }, [validationData]);

  const validationStatus = useMemo(() => lastRetrievedValidationData?.documentRequestUserFile?.validationStatus, [lastRetrievedValidationData]);
  const documentAnalysis = useMemo(() => lastRetrievedValidationData?.documentAnalysis, [lastRetrievedValidationData]);

  useEffect(() => {
    if (!showDocumentValidationStatus || !documentRequestUserFileId) return;

    getDocumentFileValidation({ fileId: documentRequestUserFileId });
  }, [documentRequestUserFileId, showDocumentValidationStatus, getDocumentFileValidation]);

  const debouncedGetDocumentFileValidation = useMemo(() => debounce(getDocumentFileValidation, 3000, {
    leading: true,
  }), [getDocumentFileValidation]);

  useEffect(() => {
    // Polling for validation status updates at the document request level
    // whenever a file was just uploaded
    if (!lastFileUploadedAt) return;

    const now = new Date();
    const diffInMillis = (now.getTime() - lastFileUploadedAt.getTime());
    const wasJustUploaded = diffInMillis < 15_000; // within last 15s

    if (!wasJustUploaded || !documentRequestUserFileId) return;

    // Polling for when an image was just uploaded
    debouncedGetDocumentFileValidation({ fileId: documentRequestUserFileId });
  }, [
    lastFileUploadedAt,
    documentRequestUserFileId,
    debouncedGetDocumentFileValidation,
    validationData,
  ]);

  const handleClick = (): void => {
    if (!isEdit && (documents?.length || 0) === 0) {
      documentUploaderRef.current?.uploadDocument();
    } else if ((documents?.length || 0) > 0 && documentRequestUserFileId && documentRequestUserId && creatorSlug) {
      if (isInDashboard || isInUserProfile) {
        openDocumentRequestUserFilePane({ documentRequestUserFileId });
      } else {
        router.push(
          getUrl(IDENTIFIERS.DOCUMENT_REQUEST_USER_FILE_DETAIL, {
            creatorSlug,
            documentRequestUserFileId,
            documentRequestUserId,
          }),
        );
      }
    } else {
      logError(new Error('Could not go to next step from DocumentFileCard'), {
        creatorSlug,
        documentIds: documents?.map(d => d.id),
        documentRequestUserFileId,
        documentRequestUserId,
      });
    }
  };

  const handleDeleteClick = async (): Promise<void> => {
    if (documentRequestUserFileId && documentRequestUserId) {
      setIsDeleting(true);
      await deleteFn({ fileId: documentRequestUserFileId, id: documentRequestUserId });
      if (refetchFiles) {
        await refetchFiles();
      }
      setIsDeleting(false);
    }
  };

  const downloadFiles = (overrideToken?: string): void => {
    if (!accessToken && !overrideToken) {
      return;
    }
    if (documents) {
      const zipDownloadLink = getNoodleDocumentsUrl('/documents/archive.pdf', {
        accessToken: accessToken || overrideToken,
        archiveName: fileName,
        documentId: documents.map((d) => d.id),
      });
      const a = window.document.createElement('a');
      a.href = zipDownloadLink;
      a.style.display = 'none';
      a.download = `${fileName}.pdf`;
      window.document.body.appendChild(a);
      a.click();
    }
  };

  const handleDownloadFilesAsPDF = async (): Promise<void> => {
    if (!accessToken) {
      setIsAccessModalOpen(true);
    } else {
      downloadFiles();
    }
  };

  const handleUpdateIsRequired = async (): Promise<void> => {
    if (documentRequestUserFileId && documentRequestUserId) {
      setIsUpdatingIsRequired(true);
      await updateFn({ fileId: documentRequestUserFileId, id: documentRequestUserId, isRequired: !isRequired });
      if (refetchFiles) {
        await refetchFiles();
      }
      setIsUpdatingIsRequired(false);
    }
  };

  const handleUploadFinish: NonNullable<Parameters<typeof DocumentUploader>[0]['onFinishUpload']> = async document => {
    setIsUploadingFiles(false);
    if (onFinishUpload && documentRequestUserFileId) {
      await onFinishUpload({ document, documentRequestUserFileId });
    }

    setLastFileUploadedAt(new Date());
  };

  const handleUploadStart = (): void => {
    setIsUploadingFiles(true);
  };

  const FileComponent: FC<{ document: Pick<ApiModels.Document, 'id' | 'fileName' | 'createdAt' | 'uploadedBy' | 'mimeType' | 'uploadedAt'> }> = ({ document }) => {
    if (!documentRequestUserFileId || !creatorSlug || !documentRequestUserFileId || !documentRequestUserId) return <></>;
    return (
      <button
        key={document.id}
        className={s.file}
        onClick={() => {
          if (isInDashboard || isInUserProfile) {
            openDocumentRequestUserFilePane({ documentRequestUserFileId });
          } else {
            router.push(
              getUrl(IDENTIFIERS.DOCUMENT_REQUEST_USER_FILE_DETAIL, {
                creatorSlug,
                documentRequestUserFileId,
                documentRequestUserId,
              }),
            );
          }
        }}
      >
        <File document={document} />
      </button>
    );
  };

  return (
    <div className={s.wrapper}>
      {documents && documents.length > 0
        && <button
          className={s.trigger}
          onClick={() => openDocumentRequestUserFilePane({ documentRequestUserFileId })}
          aria-label='View uploaded files'
        />}
      <div className={s.card}>
        <>
          {showDocumentValidationStatus
            && <ValidationStatusButton
              status={validationStatus?.status}
              approvedBy={validationStatus?.approvedBy}
              documentAnalysis={{
                documentAnalysis,
                summary: null,
              }}
              onClick={() => {
                if (!documentRequestUserFileId) return;
                openDocumentRequestUserFilePane({ documentRequestUserFileId });
              }}
              fileId={documentRequestUserFileId}
              getDocumentFileValidation={getDocumentFileValidation}
              isRollUp
            />}
          {actionRequired && <BannerStatus status='error' label="Action required" className={s.banner} />}
          <p className={s.title}>
            {fileName} {!isRequired && <em>If Available</em>} {isRequired && <b>Required</b>}{' '}
          </p>
          {fileDescription && <p className="caption" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(convertLinksToAnchors(fileDescription)) }} />}
          {(documents?.length || 0) === 0 && isForYourEyesOnly && creatorName && (
            <>
              <p className="caption">
                <Lock weight="fill" /> This is a sensitive document that will only be visible to the person who uploads it and {creatorName}&apos;s team.
              </p>
            </>
          )}
          {documents && <>
            {(documents.length || 0) > 1 && (
              <CollapsibleArea
                title={`${documents.length} uploaded documents`}
                className={s.collapsible}
                caretPosition='left'
              >
                {documents.map(document => <FileComponent key={document.id} document={document} />)}
              </CollapsibleArea>
            )}
            {(documents.length || 0) === 1 && (
              <FileComponent key={documents[0].id} document={documents[0]} />
            )}
          </>}
          {(documents?.length || 0) > 0 && (
            <Buttons isWrapper onClick={() => documentUploaderRef.current?.uploadDocument()} className={s.uploadButton}>
              <p className="body-sm" style={{ color: 'var(--color-primary)' }}>
                Upload more
              </p>
            </Buttons>
          )}
          <Buttons isWrapper onClick={handleClick} className={s.uploadButton} disabled={!hasPermission && !isEdit}>
            <CardLabel
              isUploaded={Boolean((documents?.length || 0) > 0)}
              isEdit={isEdit}
              isUploading={isUploadingFiles}
              hasPermission={hasPermission ?? true}
            />
          </Buttons>
          {uploadError && (
            <p className="caption" style={{ color: 'var(--color-error)' }}>
              Failed to upload document. Please try again.
            </p>
          )}
        </>
        {documentRequestUserFileId && (
          <DocumentUploader
            ref={documentUploaderRef}
            documentRequestUserFileId={documentRequestUserFileId}
            onUploadProgressChange={setUploadingProgress}
            onUploadError={setUploadError}
            onFinishUpload={handleUploadFinish}
            onUploadStart={handleUploadStart}
            isMultiple
          />
        )}
      </div>
      {uploadingProgress > 0 && (
        <div className={s.topRight}>
          <CircularProgress progress={uploadingProgress} size={20} />
        </div>
      )}
      {(canDelete || canUpdateIsRequired) && !(uploadingProgress > 0) && (
        <SecondaryActions
          ariaLabel="Document actions"
          hideTooltip
          position="bottom-right"
          className={s.topRight}
          options={[
            ...((canUpdateIsRequired && [
              {
                disabled: isUpdatingIsRequired,
                value: isRequired ? 'Make optional' : 'Make required',
              },
            ])
              || []),
            ...((isInDashboard && [
              {
                disabled: false,
                value: 'Download all as a single PDF',
              },
            ]) || []),
            ...((canDelete && [
              {
                destructive: true,
                disabled: isDeleting,
                value: 'Remove',
              },
            ])
                || []),
          ]}
          onSelect={e => {
            if (e === 'Remove') {
              handleDeleteClick();
            } else if (e === 'Make optional' || e === 'Make required') {
              handleUpdateIsRequired();
            } else if (e === 'Download all as a single PDF') {
              handleDownloadFilesAsPDF();
            }
          }}
        />
      )}
      {isAccessModalOpen && (
        <DocumentAccessModal onSubmit={downloadFiles} onClose={() => setIsAccessModalOpen(false)} />
      )}
    </div>
  );
};

export default DocumentFileCard;
