import React, { FC } from 'react';
import InputField from '@components/FormFields/InputField/InputField';
import Spacer from '@components/Spacer';
import CheckBox from '@components/FormFields/CheckBox';
import { m } from 'framer-motion';

export type Document = {
  description: string;
  id: string;
  isCollapsed: boolean;
  isRequired: boolean;
  title: string;
  order: number;
  isForYourEyesOnly: boolean;
};

type Props = {
  document: Document;
  handleChange: (key: string, value: string | boolean) => void;
};

const DocumentForm: FC<Props> = ({ document, handleChange }) => (
  <m.div layout initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.1 } }}>
    <InputField
      id="title"
      name="title"
      values={{ title: document.title }}
      onChange={(val) => handleChange('title', val)}
      label="Title of document"
      hasFixedHeight
    />
    <InputField
      id="description"
      name="description"
      rows={3}
      values={{ description: document.description }}
      onChange={(val) => handleChange('description', val)}
      label="Description"
    />
    <Spacer size={16} />
    <CheckBox
      id={`${document.id}isRequired`}
      name={`${document.id}isRequired`}
      values={{ [`${document.id}isRequired`]: document.isRequired }}
      onChange={() => handleChange('isRequired', !document.isRequired)}
      label={'Required'}
      isSwitch
      isSwitchGreenStyle
      hasFixedHeight={false}
    />
    <CheckBox
      id={`${document.id}isForYourEyesOnly`}
      name={`${document.id}isForYourEyesOnly`}
      values={{ [`${document.id}isForYourEyesOnly`]: document.isForYourEyesOnly }}
      onChange={() => handleChange('isForYourEyesOnly', !document.isForYourEyesOnly)}
      label={`Document will only be visible to your team and whoever uploads this document.`}
      isSwitch
      isSwitchGreenStyle
      hasFixedHeight={false}
    />
  </m.div>
);

export default DocumentForm;
