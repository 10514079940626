import React, { ChangeEvent, useCallback, useRef, forwardRef, useImperativeHandle } from 'react';
import { Upload, UploadOptions } from 'tus-js-client';
import { NOODLE_DOCUMENT_UPLOAD_HOST } from '@configuration/client';
import { nanoid } from 'nanoid';
import { logError } from '@providers/ErrorTracking';
import Bluebird from 'bluebird';
import { useUser, useToast } from '@hooks';
import * as tsClient from '@tsClient';
import useNoodleApi from '@hooks/useNoodleApi';
import getS3OriginIdFromUrl from '@helpers/tusd/getS3OriginIdFromUrl';
import { ToastTypeVariants } from '@context/ToastContext';
import { mixpanelTrack } from '@/providers/Mixpanel';

type UploadedDocument = Awaited<ReturnType<typeof tsClient.documents.getDocumentByUploadId>>;

type Props = {
  onUploadProgressChange: (progress: number) => void;
  onUploadError: (e: Error | null) => void;
  onFinishUpload?: (document: UploadedDocument | null) => Promise<void> | void;
  isMultiple?: boolean;
  onUploadStart?: () => void;
} & (
  | {
      id: string;
      creatorId: string;
      documentRequestUserFileId?: never;
    }
  | {
      id?: never;
      creatorId?: never;
      documentRequestUserFileId: string;
    }
);

export type DocumentUploaderRef = {
  uploadDocument: () => void;
};

type UploadedFileState = {
  numFiles: number;
  uploadedFiles: number;
};

// eslint-disable-next-line prefer-arrow-callback
const DocumentUploader = forwardRef(function DocumentUploader(
  { creatorId, id, documentRequestUserFileId, onUploadProgressChange, onUploadError, onFinishUpload, isMultiple = false, onUploadStart }: Props,
  ref,
) {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const uploadedFilesRef = useRef<UploadedFileState | null>(null);
  const [user] = useUser();
  const onUploadProgressChangeRef = useRef(onUploadProgressChange);
  onUploadProgressChangeRef.current = onUploadProgressChange;
  const onUploadErrorRef = useRef(onUploadError);
  onUploadErrorRef.current = onUploadError;
  const onFinishUploadRef = useRef(onFinishUpload);
  onFinishUploadRef.current = onFinishUpload;
  const onUploadStartRef = useRef(onUploadStart);
  onUploadStartRef.current = onUploadStart;
  const addToast = useToast();

  const { getData: getDocumentByUploadId } = useNoodleApi(tsClient.documents.getDocumentByUploadId, { toastOnError: true });

  useImperativeHandle(
    ref,
    () => ({
      uploadDocument: () => {
        inputFileRef?.current?.click();
      },
    }),
    [],
  );

  const handleAddFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const filesToUpload = event.target.files;

      if (!filesToUpload) {
        return;
      }
      if (!user) {
        return;
      }

      const fileNames: (string | null)[] = [];
      let numFiles = 0;
      for (let i = 0; i < filesToUpload.length; i += 1) {
        numFiles += 1;
        const fileToUpload = filesToUpload.item(i);
        fileNames.push(`${fileToUpload?.name ?? null} type=${fileToUpload?.type ?? 'unknown'}`);
      }
      mixpanelTrack('DocumentUploader - file added', { fileNames: fileNames.join(','), numFiles });

      uploadedFilesRef.current = {
        numFiles: filesToUpload.length,
        uploadedFiles: 0,
      };
      if (onUploadStartRef.current) {
        onUploadStartRef.current();
      }
      for (let i = 0; i < filesToUpload.length; i += 1) {
        const fileToUpload = filesToUpload.item(i);
        if (!fileToUpload) {
          // eslint-disable-next-line no-continue
          continue;
        }
        // manually check for correct extension
        const fileName = fileToUpload.name;
        const dot = fileName.lastIndexOf('.') + 1;
        const extFile = fileName.slice(dot, fileName.length).toLowerCase();
        if (!['jpg', 'png', 'jpeg', 'pdf'].includes(extFile)) {
          return;
        }

        const headers: ConstructorParameters<typeof Upload>[1]['headers'] = {};
        if (user?.token) {
          headers.authorization = user.token;
        }
        let allUploadedTime = 0;
        onUploadProgressChangeRef.current(0);
        onUploadErrorRef.current(null);

        const metadata: UploadOptions['metadata'] = {
          contentType: fileToUpload.type,
          filename: fileToUpload.name,
          filetype: fileToUpload.type,
          personId: user.id,
          // just to give context when looking at log insights
          referenceId: nanoid(),
        };

        if (creatorId) {
          metadata.creatorId = creatorId;
        }

        if (documentRequestUserFileId) {
          metadata.documentRequestUserFileId = documentRequestUserFileId;
        }

        const upload = new Upload(fileToUpload, {
          endpoint: `${NOODLE_DOCUMENT_UPLOAD_HOST}/uploads`,
          headers,
          metadata,
          onError: error => {
            console.error(`Failed because: ${error}`);
            logError(error);
            onUploadErrorRef.current(error);
            if ((uploadedFilesRef.current?.numFiles || 0) === 1) {
              onUploadProgressChangeRef.current(0);
            }
          },
          onProgress: (bytesUploaded, bytesTotal) => {
            const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
            if ((uploadedFilesRef.current?.numFiles || 0) === 1) {
              onUploadProgressChangeRef.current(Number(percentage));
            }
            console.info(bytesUploaded, bytesTotal, `${percentage}%`, Date.now());
            if (percentage === '100.00' && allUploadedTime === 0) {
              allUploadedTime = Date.now();
            }
          },
          onSuccess: async () => {
            console.info('Time from 100%', Date.now(), (Date.now() - allUploadedTime) / 1000);
            console.info('Download %s from %s', (upload.file as File)?.name || 'unknown', upload.url);
            addToast(ToastTypeVariants.SUCCESS, 'Document uploaded successfully');

            const uploadId = getS3OriginIdFromUrl(upload?.url);
            let document: UploadedDocument | null = null;

            if (uploadId) {
              const response = await getDocumentByUploadId({ uploadId });
              if (!response.data) {
                logError(new Error('Failed to fetch just uploaded document'), { referenceId: metadata.referenceId, uploadId });
              } else {
                document = response.data;
              }
            } else {
              // Delay to ensure document exists in document service
              await Bluebird.delay(1000);
            }

            if (uploadedFilesRef.current) {
              uploadedFilesRef.current.uploadedFiles += 1;
              if (uploadedFilesRef.current.uploadedFiles === uploadedFilesRef.current.numFiles && onFinishUploadRef.current) {
                await onFinishUploadRef.current(document);
              }
            }

            if ((uploadedFilesRef.current?.numFiles || 0) === 1) {
              onUploadProgressChangeRef.current(0);
            }
          },
          retryDelays: [0, 3000, 5000, 10000, 20000],
        });
        upload.start();
      }
    },
    [user, documentRequestUserFileId, getDocumentByUploadId, addToast],
  );

  return (
    <input
      accept="application/pdf,image/*"
      id={id || documentRequestUserFileId || 'document-uploader'}
      type="file"
      ref={inputFileRef}
      multiple={isMultiple}
      style={{
        display: 'none',
        height: 0,
        position: 'absolute',
        width: 0,
        zIndex: '-1',
      }}
      onClick={e => e.stopPropagation()}
      onChange={handleAddFile}
    />
  );
});

export default DocumentUploader;
