import { FC, useState } from 'react';
import Modal from '@components/Modal';
import DocumentForm, { Document } from '@components/DocumentForm';
import { nanoid } from 'nanoid';
import Button from '@components/DesignLibrary/Button';
import Spacer from '@components/Spacer';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import s from './AddDocumentModal.module.scss';

type Props = {
  onClose: () => void;
  documentRequestUserId: string;
  refetchFiles: () => Promise<void>;
};

const AddDocumentModal: FC<Props> = ({ onClose, documentRequestUserId, refetchFiles }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [document, setDocument] = useState<Document>({
    description: '',
    id: nanoid(),
    isCollapsed: false,
    isForYourEyesOnly: false,
    isRequired: true,
    order: 1,
    title: '',
  });

  const { getData: addFileFn } = useNoodleApi(tsClient.documentRequests.createDocumentRequestUserFile);

  const handleChange = (key: string, value: string | boolean): void => {
    setDocument(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSubmit = async (): Promise<void> => {
    setIsSubmitting(true);
    await addFileFn({
      ...document,
      id: documentRequestUserId,
    });
    await refetchFiles();
    setIsSubmitting(false);
    onClose();
  };

  return (
    <Modal
      hideBottomInset

      title='Add Document'
      onClose={onClose}

    >
      <div className={s.container}>
        <DocumentForm document={document} handleChange={handleChange} />
        <Spacer size='16px' />
        <Button
          loading={isSubmitting}
          disabled={!document.title}
          variant='primary'
          size='md'
          fullWidth
          onClick={handleSubmit}
        >
          Add document
        </Button>
      </div>
    </Modal>
  );
};

export default AddDocumentModal;
