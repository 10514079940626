// keep in sync with URL_REGEX in @helpers/appConsts, but this is not /g
const URL_REGEX = /(\b(?:https?|ftp):\/\/|www\.)[\w_-]+(?:(?:\.[\w_-]+)+)([\w.,@?^=%&:/~+#-;]*[\w@?^=%&/~+#-])/;

const convertLinksToAnchors = (message: string): string => {
  let restOfMessage = message;
  let messageWithAnchors = '';
  let previousLength = -1;

  // break out of loop if there is nothing more to look at, or (as circuit breaker) the message didn't grow.
  while (restOfMessage !== '' && messageWithAnchors.length > previousLength) {
    previousLength = messageWithAnchors.length;
    const match = restOfMessage.match(URL_REGEX);
    if (!match || match.index === undefined) {
      messageWithAnchors = `${messageWithAnchors}${restOfMessage}`;
      restOfMessage = '';
    } else {
      const beforeUrl = restOfMessage.slice(0, match.index);
      const url = match[0];
      const isWww = url.startsWith('www.');
      const anchor = `<a href="${isWww ? "https://" : ""}${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      messageWithAnchors = `${messageWithAnchors}${beforeUrl}${anchor}`;
      restOfMessage = restOfMessage.slice(match.index + url.length);
    }
  }

  return messageWithAnchors;
};

export default convertLinksToAnchors;
