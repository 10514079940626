import Lock from '@components/Icons/Lock';

type Props = {
  isEdit: boolean;
  isUploading: boolean;
  hasPermission: boolean;
  isUploaded: boolean;
};

const CardLabel: React.FC<Props> = ({ isEdit, isUploading, hasPermission, isUploaded }) => {
  if (!hasPermission) {
    return (
      <p className='caption' style={{ color: 'var(--color-gray-75)' }}>
        <Lock size={12} weight="fill" style={{ marginRight: 4 }} />
        Sensitive document
      </p>
    );
  }

  if (isUploaded) {
    return <></>;
  }

  let text: string;

  if (isEdit) {
    text = 'Edit';
  } else if (isUploading) {
    text = 'Uploading...';
  } else {
    text = 'Upload';
  }

  return (
    <div style={{ display: 'flex', gap: 8 }}>
      <p className="body-sm" style={{ color: 'var(--color-primary)' }}>
        {text}
      </p>
    </div>
  );
};

export default CardLabel;
