import { FC } from 'react';
import Modal from '@components/Modal';
import ForbiddenToCreator from '@providers/DocumentAccess/ForbiddenToCreator';
import s from '@modals/ModalsCommon.module.scss';

type Props = {
  onClose: () => void;
  onSubmit: (t?: string) => void;
};

const DocumentAccessModal: FC<Props> = ({ onClose, onSubmit }) => {

  const handleSubmit = (token?: string): void => {
    onSubmit(token);
    onClose();
  };

  return (
    <Modal
      hideBottomInset

      title='Enter access code'
      onClose={onClose}

    >
      <div className={s.container}>
        <ForbiddenToCreator onSubmit={handleSubmit} />
      </div>
    </Modal>
  );
};

export default DocumentAccessModal;
