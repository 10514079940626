import LoggedInUserGate from '@components/Auth/LoggedInUserGate';
import { DocumentAccessProvider } from '@providers/DocumentAccess';
import DocumentRequestUserDetail from './DocumentRequestUserDetail';

type Props = Omit<Parameters<typeof DocumentRequestUserDetail>[0], 'children'>;

const DocumentRequestUserDetailWithAccessProvider: React.FC<Props> = (props) => (
  <LoggedInUserGate isAnonymousAllowed={false}>
    <DocumentAccessProvider>
      <DocumentRequestUserDetail {...props} />
    </DocumentAccessProvider>
  </LoggedInUserGate>
);

export default DocumentRequestUserDetailWithAccessProvider;
