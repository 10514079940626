import { useQueryState } from 'nuqs';

export const QS_KEY = 'paneDocumentRequestUserFileId';

// @todo - figure out how to move the stuff in Panes.tsx's useEffect into this file somehow...

export const useOpenDocumentRequestUserFilePane = ():
((args: { documentRequestUserFileId: string}) => void) => {
  const [_documentRequestUserFileId, setDocumentRequestUserFileId] = useQueryState(QS_KEY, { history: 'push' });

  return ({ documentRequestUserFileId }) => setDocumentRequestUserFileId(documentRequestUserFileId);
};
