import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/persons/{id}']['GET'];

type GetPersonResponse = ThisApi['response'];

const getPersonById = async ({ id }: ThisApi['params']): Promise<GetPersonResponse> => {
  const response = await noodleApiClient.get(`/persons/${id}`);
  return response.data;
};

export default getPersonById;