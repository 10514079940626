import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { mixpanelTrack } from '@providers/Mixpanel';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import ProgressIndicator from '@components/ProgressIndicator';
import {
  Document,
  DocumentRequestFile,
  DocumentRequestFileStatus,
  DocumentRequestStatus,
  DocumentRequestUserFile,
} from '@typings/document-api-models';
import DocumentFileCard from '@components/Document/DocumentFileCard';
import FileArrowDown from '@components/Icons/FileArrowDown';
import Spacer from '@components/Spacer';
import { DocumentAccessContext, DocumentAccessGate } from '@providers/DocumentAccess';
import { useIsMobile, useUser } from '@hooks';
import CustomLink from '@components/CustomLink';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import preloadDocument from '@helpers/preloadDocument';
import { logError } from '@providers/ErrorTracking';
import teamsContext from '@providers/Teams/TeamsContext';
import Buttons from '@components/Buttons/Buttons';
import Modal from '@components/Modal';
import UserImage from '@components/UserImage';
import { getUserWorkflowById } from '@tsClient/workflows';
import AddDocumentModal from '@modals/AddDocumentModal';
import Head from 'next/head';
import Header from '@components/DesignLibrary/Header';
import userWorkflowToContext from '@helpers/userWorkflowToContext';
import PaperPlaneRight from '@components/Icons/PaperPlaneRight';
import Check from '@components/Icons/Check';
import Reopen from '@components/Icons/Reopen';
import Tabs from '@components/DesignLibrary/Tabs';
import { SIZES } from '@styles/media';
import BannerStatus from '@components/DesignLibrary/BannerStatus';
import MainPanelSubLayout from '@layouts/MainPanelSubLayout';
import WidgetProgress from '@components/DesignLibrary/Widget/Progress';
import s from './DocumentRequestUserDetail.module.scss';

type ThisDocumentRequestUserFile = Pick<
  DocumentRequestUserFile,
  'id' | 'status' | 'isRequired' | 'hasPermission' | 'order' | 'fileName' | 'fileDescription' | 'isForYourEyesOnly'
> & {
  documentRequestFile: Pick<DocumentRequestFile, 'fileName' | 'fileDescription' | 'order' | 'isForYourEyesOnly'> | null;
  documents: Array<Pick<Document, 'id' | 'fileName' | 'createdAt' | 'uploadedBy' | 'mimeType' | 'uploadedAt'>>;
};
type Props = {
  documentRequestUserId: string;
  creatorSlug: string;
};

const DocumentRequestUserDetail: FC<Props> = ({ documentRequestUserId, creatorSlug }) => {
  const [documentRequestUserFiles, setDocumentRequestUserFiles] = useState<ThisDocumentRequestUserFile[]>([]);
  const { accessToken } = useContext(DocumentAccessContext);
  const { creatorId } = useContext(teamsContext);
  const [compiledBlobUrl, setCompiledBlobUrl] = useState<string | null>(null);
  const [isUpdatingDocumentRequest, setIsUpdatingDocumentRequest] = useState(false);
  const [isConfirmSubmitModalOpen, setIsConfirmSubmitModalOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState<'pending' | 'uploaded'>('pending');
  const [isAddDocumentModalOpen, setAddDocumentModalOpen] = useState(false);
  const [containerOffset, setContainerOffset] = useState(152);
  const [user] = useUser();
  const userId = user?.id;
  const isMobile = useIsMobile();
  const isTabsLayout = useIsMobile(SIZES.lg);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    mixpanelTrack('DocumentRequest - viewed', { creatorSlug, documentRequestUserId });
  }, [creatorSlug, documentRequestUserId]);

  const {
    data: documentRequestUser,
    getData: getDocumentRequestUserFn,
    fetchingState: { isFetching: isFetchingDocumentRequestUser },
  } = useNoodleApi(tsClient.documentRequests.getDocumentRequestUser);

  const {
    data: documentRequestUserFilesLoaded,
    getData: getDocumentRequestUserFilesFn,
    fetchingState: { isFetching: isFetchingDocumentRequestUserFiles },
  } = useNoodleApi(tsClient.documentRequests.getDocumentRequestUserFiles);

  const { getData: getDocumentRequestUserFile } = useNoodleApi(tsClient.documentRequests.getDocumentRequestUserFile);

  const { data: creator, getData: getCreatorFn } = useNoodleApi(tsClient.getCreator);

  const { getData: completeDocumentRequestFn } = useNoodleApi(tsClient.documentRequests.completeDocumentRequest, {
    toastOnError: true,
  });
  const { getData: reOpenDocumentRequestFn } = useNoodleApi(tsClient.documentRequests.reOpenDocumentRequest, {
    toastOnError: true,
  });
  const { getData: updateDocumentRequestUser } = useNoodleApi(tsClient.documentRequests.updateDocumentRequestUser, {
    toastOnError: true,
  });
  const { data: workflowData, getData: getWorkflow } = useNoodleApi(getUserWorkflowById, {
    toastOnError: true,
  });

  const refetchFiles = useCallback(async () => {
    await Promise.all([
      getDocumentRequestUserFilesFn({
        accessToken: accessToken || undefined,
        id: documentRequestUserId,
        perPage: 1000,
      }),
      getDocumentRequestUserFn({ id: documentRequestUserId }),
    ]);
    setIsUpdatingDocumentRequest(false);
  }, [accessToken, getDocumentRequestUserFilesFn, documentRequestUserId, getDocumentRequestUserFn]);

  useEffect(() => {
    getDocumentRequestUserFn({ id: documentRequestUserId });
  }, [getDocumentRequestUserFn, documentRequestUserId]);

  useEffect(() => {
    if (creatorSlug) {
      getCreatorFn({ creatorSlug });
    }
  }, [getCreatorFn, creatorSlug]);

  useEffect(() => {
    if (documentRequestUser?.userWorkflowId) {
      getWorkflow({ userWorkflowId: documentRequestUser.userWorkflowId });
    }
  }, [documentRequestUser?.userWorkflowId, getWorkflow]);

  useEffect(() => {
    if ((accessToken || userId) && documentRequestUserId) {
      getDocumentRequestUserFilesFn({
        accessToken: accessToken || undefined,
        id: documentRequestUserId,
        perPage: 1000,
      });
    }
  }, [getDocumentRequestUserFilesFn, accessToken, userId, documentRequestUserId]);

  useEffect(() => {
    if (containerRef.current && !isTabsLayout) {
      setContainerOffset(containerRef.current.getBoundingClientRect().top);
    }
  }, [isTabsLayout]);

  useEffect(() => {
    if (documentRequestUser?.compiledDocument?.id) {
      preloadDocument({
        accessToken,
        id: documentRequestUser.compiledDocument.id,
      })
        .then(blob => {
          const typeBlob = new Blob([blob], { type: documentRequestUser.compiledDocument?.mimeType });
          const blobPreviewUrl = window.URL.createObjectURL(typeBlob);
          setCompiledBlobUrl(blobPreviewUrl);
        })
        .catch(error => {
          logError(error);
        });
    }
  }, [documentRequestUser, accessToken]);

  useEffect(() => {
    if (documentRequestUserFilesLoaded) {
      setDocumentRequestUserFiles(documentRequestUserFilesLoaded.items);
    }
  }, [documentRequestUserFilesLoaded]);

  const handleDocumentUploaded: NonNullable<Parameters<typeof DocumentFileCard>[0]['onFinishUpload']> = async ({ documentRequestUserFileId }) => {
    const response = await getDocumentRequestUserFile({
      fileId: documentRequestUserFileId,
    });
    if (response.data) {
      const newDocumentRequestUserFile: ThisDocumentRequestUserFile = response.data;
      setDocumentRequestUserFiles(current => [...current.filter(file => file.id !== newDocumentRequestUserFile.id), newDocumentRequestUserFile]);
    }
  };

  const completeDocumentRequest = async (): Promise<void> => {
    setIsUpdatingDocumentRequest(true);
    await completeDocumentRequestFn({ accessToken: accessToken || undefined, documentRequestUserId });
    await refetchFiles();
  };

  const reOpenDocumentRequest = async (): Promise<void> => {
    setIsUpdatingDocumentRequest(true);
    await reOpenDocumentRequestFn({ accessToken: accessToken || undefined, documentRequestUserId });
    await refetchFiles();
  };

  const submitDocumentRequest = async (): Promise<void> => {
    setIsUpdatingDocumentRequest(true);
    await updateDocumentRequestUser({
      id: documentRequestUserId,
      status: DocumentRequestStatus.IN_REVIEW,
      userWorkflowId: documentRequestUser?.userWorkflowId || null,
    });
    await refetchFiles();
    setIsConfirmSubmitModalOpen(false);
  };

  if (!documentRequestUser || isFetchingDocumentRequestUser) {
    return (
      <>
        <Spacer size={80} />
        <ProgressIndicator isCentered isPage />
      </>
    );
  }

  const requiredFiles = documentRequestUserFiles.filter(f => f.isRequired);
  const uploadedRequiredFiles = requiredFiles.filter(f => f.status === DocumentRequestFileStatus.COMPLETE);
  const isComplete = documentRequestUser.status === DocumentRequestStatus.SUCCEEDED;
  const isInReview = documentRequestUser.status === DocumentRequestStatus.IN_REVIEW;
  const areAllRequiredUploaded = requiredFiles.length === uploadedRequiredFiles.length && !isFetchingDocumentRequestUserFiles;
  const isCreator = creatorId === documentRequestUser?.creatorId;

  const noTabs = documentRequestUserFiles.filter(f => f.status !== DocumentRequestFileStatus.COMPLETE).length === 0
    || documentRequestUserFiles.filter(f => f.status === DocumentRequestFileStatus.COMPLETE).length === 0;

  const outstandingFiles = documentRequestUserFiles
    .filter(f => f.status !== DocumentRequestFileStatus.COMPLETE)
    .sort((a, b) => (a.order || a.documentRequestFile?.order || 0) - (b.order || b.documentRequestFile?.order || 0))
    .sort((a, b) => (a.isRequired ? -1 : 1) - (b.isRequired ? -1 : 1));

  const uploadedFiles = documentRequestUserFiles
    .filter(f => f.status === DocumentRequestFileStatus.COMPLETE)
    .sort((a, b) => (a.order || a.documentRequestFile?.order || 0) - (b.order || b.documentRequestFile?.order || 0))
    .sort((a, b) => (a.isRequired ? -1 : 1) - (b.isRequired ? -1 : 1))
    .sort((a, b) => {
      const getMostRecentCreationDate = (documents: Array<{ createdAt: string }>): number => (
        Math.max(...documents.map(doc => new Date(doc.createdAt).getTime()))
      );

      const aMostRecentDate = getMostRecentCreationDate(a.documents);
      const bMostRecentDate = getMostRecentCreationDate(b.documents);

      return bMostRecentDate - aMostRecentDate;
    });

  return (
    <>
      {isAddDocumentModalOpen && (
        <AddDocumentModal onClose={() => setAddDocumentModalOpen(false)} documentRequestUserId={documentRequestUserId} refetchFiles={refetchFiles} />
      )}
      <Head>
        <title>
          {documentRequestUser.documentRequest.title}
        </title>
      </Head>

      <MainPanelSubLayout
        header={{
          actions: [
            ...((documentRequestUser.documentRequest.isForCreator ? !isCreator || isInReview : isCreator)
            && !isComplete && areAllRequiredUploaded && isInReview
              ? [
                {
                  icon: Check,
                  label: 'Mark document request as completed',
                  onClick: completeDocumentRequest,
                },
              ]
              : []),
            ...((documentRequestUser.documentRequest.isForCreator ? !isCreator || isInReview : isCreator)
            && isComplete
              ? [
                {
                  icon: Reopen,
                  label: 'Re-open document request',
                  onClick: reOpenDocumentRequest,
                },
              ]
              : []),
            ...(!isComplete && isCreator
              ? [{
                label: 'Add an item',
                onClick: () => setAddDocumentModalOpen(true),
              }]
              : []),
            ...(!isComplete && !isInReview && areAllRequiredUploaded
              ? [
                {
                  icon: PaperPlaneRight,
                  label: 'Submit for review',
                  onClick: () => setIsConfirmSubmitModalOpen(true),
                },
              ]
              : []),
          ],
          description:
            !areAllRequiredUploaded
            && !isCreator
            && !documentRequestUser.documentRequest.isForCreator
            && 'You can only submit this document upload request for review after all required documents are uploaded.',
          title: documentRequestUser.documentRequest.title,
        }}
        pageContext={{
          isFetching: !workflowData,
          items: workflowData ? userWorkflowToContext(workflowData) : [],
        }}
        rightPanel={{
          component: (
            <>
              {
                <div className={s.rightPanel}>
                  {documentRequestUser.compiledDocument?.id && compiledBlobUrl && (
                    <div className={s.banner}>
                      <a href={compiledBlobUrl} className={s.download} download={documentRequestUser.compiledDocument.fileName}>
                        <FileArrowDown weight="fill" size={16} />
                        Download compiled documents
                      </a>
                    </div>
                  )}
                  <WidgetProgress
                    title="Progress"
                    type="required documents"
                    progress={{ completed: uploadedRequiredFiles.length, total: requiredFiles.length }}
                  />
                </div>
              }
            </>
          ),
          title: 'Document request status',
        }}
      >
        <DocumentAccessGate
          documentCreatorId={documentRequestUser.creatorId}
          documentPersonId={documentRequestUser.personId}
          isCollaborator={documentRequestUser.isCollaborator}
          isCenteredVertically
        >
          <div className={s.wrapper} ref={containerRef}>
            {isCreator
              ? (
                <>
                  {(isComplete || isInReview || areAllRequiredUploaded) && !isFetchingDocumentRequestUserFiles && (
                    <div className={s.banner}>
                      <BannerStatus
                        status={isComplete ? 'success' : 'warning'}
                        label={
                          (isComplete && 'Document request complete.')
                        || (isInReview && !documentRequestUser.documentRequest.isForCreator && 'Contact has uploaded all required documents and has submitted this request for your review.')
                        || (isInReview && documentRequestUser.documentRequest.isForCreator && `You have uploaded all required documents and this request has been submitted.`)
                        || (documentRequestUser.documentRequest.isForCreator && `All required documents have been uploaded, but you have not yet submitted this request.`)
                        || 'Contact has uploaded all required documents, but has not yet submitted this request for review. They may still be uploading optional documents.'
                        }
                      />
                    </div>
                  )}
                </>
              )
              : (
                <>
                  {(isComplete || isInReview || areAllRequiredUploaded) && !isFetchingDocumentRequestUserFiles && (
                    <div className={s.banner}>
                      <BannerStatus
                        status={isComplete ? 'success' : 'warning'}
                        label={
                          (isComplete && 'Document request complete.')
                        || (isInReview && documentRequestUser.documentRequest.isForCreator && `${creator?.name || 'The creator'} has uploaded all required documents and has submitted this request for your review.`)
                        || (isInReview && 'You have submitted all the required documents. Your documents are now under review by the provider.')
                        || (documentRequestUser.documentRequest.isForCreator && `${creator?.name || 'The creator'} has uploaded all required documents but has not yet submitted this request for review. They may still be uploading optional documents.`)
                        || 'You have uploaded all required documents. If you are finished uploading documents, please submit.'
                        }
                      />
                    </div>
                  )}
                </>
              )}
            {!isTabsLayout && !isFetchingDocumentRequestUserFiles && (
              <div className={s.containerColumns}>
                {outstandingFiles.length > 0 && (
                  <div style={{ height: `calc(min(100vh, 100dvh) - ${containerOffset}px)` }}>
                    <Header hierarchy="h2" title={`Outstanding (${outstandingFiles.length})`} isSticky />
                    <div className={s.containerColumnsList}>
                      {outstandingFiles.map(file => (
                        <DocumentFileCard
                          canDelete={isCreator && !isComplete}
                          canUpdateIsRequired={isCreator}
                          key={file.id}
                          creatorSlug={creatorSlug}
                          creatorName={creator?.name}
                          documentRequestUserId={documentRequestUserId}
                          documentRequestUserFileId={file.id}
                          fileName={file.fileName || file.documentRequestFile?.fileName || ''}
                          fileDescription={file.fileDescription || file.documentRequestFile?.fileDescription || ''}
                          onFinishUpload={handleDocumentUploaded}
                          isRequired={file.isRequired}
                          hasPermission={file.hasPermission}
                          isForYourEyesOnly={
                            file.isForYourEyesOnly !== null ? file.isForYourEyesOnly : file.documentRequestFile?.isForYourEyesOnly || false
                          }
                          refetchFiles={refetchFiles}
                          documents={file.documents}
                          actionRequired={file.status === DocumentRequestFileStatus.ACTION_REQUIRED}
                        />
                      ))}
                    </div>
                  </div>
                )}
                <div style={{ height: `calc(min(100vh, 100dvh) - ${containerOffset}px)` }}>
                  <Header hierarchy="h2" title={`Uploaded (${uploadedFiles.length})`} isSticky />
                  <div className={s.containerColumnsList}>
                    {uploadedFiles.map(file => (
                      <DocumentFileCard
                        canDelete={isCreator && !isComplete}
                        canUpdateIsRequired={isCreator}
                        key={file.id}
                        creatorSlug={creatorSlug}
                        creatorName={creator?.name}
                        documentRequestUserId={documentRequestUserId}
                        documentRequestUserFileId={file.id}
                        fileName={file.fileName || file.documentRequestFile?.fileName || ''}
                        fileDescription={file.fileDescription || file.documentRequestFile?.fileDescription || ''}
                        onFinishUpload={handleDocumentUploaded}
                        isRequired={file.isRequired}
                        documents={file.documents}
                        hasPermission={file.hasPermission}
                        isForYourEyesOnly={
                          file.isForYourEyesOnly !== null ? file.isForYourEyesOnly : file.documentRequestFile?.isForYourEyesOnly || false
                        }
                        refetchFiles={refetchFiles}
                        actionRequired={file.status === DocumentRequestFileStatus.ACTION_REQUIRED}
                        showDocumentValidationStatus
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}

            {isTabsLayout && (
              <div className={s.container}>
                {documentRequestUser.compiledDocument?.id && compiledBlobUrl && (
                  <>
                    <a href={compiledBlobUrl} className={s.download} download={documentRequestUser.compiledDocument.fileName}>
                      <FileArrowDown weight="fill" size={16} />
                      Download compiled documents
                    </a>
                    <Spacer size="16px" />
                  </>
                )}
                {!isCreator && !isComplete && !isInReview && !documentRequestUser.documentRequest.isForCreator && (
                  <>
                    {!isMobile && <Spacer size="16px" />}
                    <Buttons
                      isSecondary
                      isFullWidth
                      onClick={() => setIsConfirmSubmitModalOpen(true)}
                      isFetching={isUpdatingDocumentRequest}
                      disabled={!areAllRequiredUploaded}
                    >
                      Submit for review
                    </Buttons>
                    {!isMobile && <Spacer size="16px" />}
                    {!areAllRequiredUploaded && (
                      <div className={s.reviewInfo}>
                        <p>You can only submit this document upload request for review after all required documents are uploaded.</p>
                      </div>
                    )}
                    {isMobile && noTabs && <Spacer size={16} />}
                  </>
                )}
                <div className={s.files}>
                  {!noTabs && (
                    <Tabs
                      inputName="status"
                      inputs={[
                        {
                          id: 'pending',
                          label: `Pending (${documentRequestUserFiles.filter(f => f.status !== DocumentRequestFileStatus.COMPLETE).length})`,
                        },
                        {
                          id: 'uploaded',
                          label: `Uploaded (${documentRequestUserFiles.filter(f => f.status === DocumentRequestFileStatus.COMPLETE).length})`,
                        },
                      ]}
                      onChange={newTab => setCurrentTab(newTab)}
                    />
                  )}
                  {isFetchingDocumentRequestUserFiles && <ProgressIndicator isCentered isPage />}
                  {(currentTab === 'pending' ? outstandingFiles : uploadedFiles).map(file => (
                    <DocumentFileCard
                      canDelete={isCreator && !isComplete}
                      canUpdateIsRequired={isCreator}
                      key={file.id}
                      creatorSlug={creatorSlug}
                      creatorName={creator?.name}
                      documentRequestUserId={documentRequestUserId}
                      documentRequestUserFileId={file.id}
                      fileName={file.fileName || file.documentRequestFile?.fileName || ''}
                      fileDescription={file.fileDescription || file.documentRequestFile?.fileDescription || ''}
                      onFinishUpload={handleDocumentUploaded}
                      isRequired={file.isRequired}
                      hasPermission={file.hasPermission}
                      isForYourEyesOnly={
                        file.isForYourEyesOnly !== null ? file.isForYourEyesOnly : file.documentRequestFile?.isForYourEyesOnly || false
                      }
                      refetchFiles={refetchFiles}
                      documents={file.documents}
                      actionRequired={file.status === DocumentRequestFileStatus.ACTION_REQUIRED}
                      showDocumentValidationStatus={currentTab !== 'pending'}
                    />
                  ))}
                  {isComplete && !isCreator && (
                    <CustomLink
                      to={getUrl(IDENTIFIERS.USER_PROFILE, {
                        creatorSlug,
                      })}
                      className={s.successCTA}
                    >
                      <small>This document request is complete</small>
                      <p>View your chat</p>
                    </CustomLink>
                  )}
                </div>
              </div>
            )}

            {isConfirmSubmitModalOpen && (
              <Modal

                title="Confirm submission"

                onClose={() => setIsConfirmSubmitModalOpen(false)}
              >
                <div className={s.confirmSubmit}>
                  {creator
                    ? (
                      <>
                        <UserImage size={80} url={creator?.person?.image} color={creator.primaryColour?.hex} name={creator.name} />
                        <h2 className="body-md-bold">Your documents will be submitted{documentRequestUser.documentRequest.isForCreator ? '' : ` to ${creator?.name} for review`}.</h2>
                        {!documentRequestUser.documentRequest.isForCreator && (
                          <p className="body-sm">
                            You will not be able to change any documents while your documents are in review. {creator.name} will notify you if any
                            documents need to be re-uploaded.
                          </p>
                        )}
                        <Buttons onClick={submitDocumentRequest} isSecondary isFullWidth isFetching={isUpdatingDocumentRequest}>
                                Submit
                        </Buttons>
                      </>
                    )
                    : (
                      <ProgressIndicator isCentered />
                    )}
                </div>
              </Modal>
            )}
          </div>
        </DocumentAccessGate>
      </MainPanelSubLayout>
    </>
  );
};

export default DocumentRequestUserDetail;
