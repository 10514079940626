import { useQueryState } from 'nuqs';

export const QS_KEY = 'paneDocumentRequestUserId';

// @todo - figure out how to move the stuff in Panes.tsx's useEffect into this file somehow...

export const useOpenDocumentRequestUserPane = (): ((args: { documentRequestUserId: string }) => void) => {
  const [_documentRequestUserId, setDocumentRequestUserId] = useQueryState(QS_KEY, { history: 'push' });

  return ({ documentRequestUserId }) => setDocumentRequestUserId(documentRequestUserId);
};