import { useQueryState } from 'nuqs';

export const QS_KEY = 'paneCustomTermsId';

// @todo - figure out how to move the stuff in Panes.tsx's useEffect into this file somehow...

export const useOpenCustomTermsPane = (): ((args: { customTermsId: string }) => void) => {
  const [_customTermsId, setCustomTermsId] = useQueryState(QS_KEY, { history: 'push' });

  return ({ customTermsId }) => setCustomTermsId(customTermsId);
};
