import { useQueryState } from 'nuqs';

export const QS_KEY = 'paneCreditReportId';

// @todo - figure out how to move the stuff in Panes.tsx's useEffect into this file somehow...

export const useOpenCreditReportPane = (): ((args: { creditReportId: string }) => void) => {
  const [_creditReportId, setCreditReportId] = useQueryState(QS_KEY, { history: 'push' });

  return ({ creditReportId }) => setCreditReportId(creditReportId);
};
