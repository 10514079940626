import * as ApiModels from '@typings/document-api-models';
import { ApiError } from 'next/dist/server/api-utils';
import React, { useContext, useEffect, useState } from 'react';
import Buttons from '@/components/Buttons';
import { GetData } from '@/hooks/useNoodleApi/typings';
import { SocketMessageType } from '@/providers/Socket/SocketContext';
import useSocketContext from '@/providers/Socket/useSocketContext';
import { ApprovedBy, DocumentAnalysisRaw, OldApprovedBy } from '@/typings/document-api-models';
import TeamsContext from '@/providers/Teams/TeamsContext';
import DocumentAnalysisModal, { DocumentAnalysis, NewDocumentAnalysis, OldDocumentAnalysis } from './DocumentAnalysisModal';
import ValidationStatus from './ValidationStatus';
import s from './DocumentStatus.module.scss';

type Props = {
  status: string | null | undefined;
  approvedBy: ApprovedBy | OldApprovedBy | null | undefined;
  documentAnalysis: DocumentAnalysis;
  isRollUp: boolean;
  onClick?: () => void;
  documentId?: string;
  fileId?: string;
  getDocumentValidation?: GetData<
    ({
      documentId,
    }: {
      documentId: string;
    }) => Promise<Pick<ApiModels.DocumentRequestUserFileDocument, 'documentAnalysis' | 'documentAnalysisRaw' | 'validationStatus'> | null>
  >;
  getDocumentFileValidation?: (args_0: {
    fileId: string;
}) => Promise<{
    data: {
        documentRequestUserFile: Pick<ApiModels.DocumentRequestUserFile, "validationStatus"> | null;
        documentAnalysis: OldDocumentAnalysis | NewDocumentAnalysis;
    } | null;
    error: ApiError | null;
}>
  documentAnalysisRaw?: DocumentAnalysisRaw;
};

const ValidationStatusButton: React.FC<Props> = ({
  status,
  approvedBy,
  documentAnalysis,
  isRollUp,
  onClick,
  documentId,
  getDocumentValidation,
  getDocumentFileValidation,
  fileId,
  documentAnalysisRaw,
}) => {
  const [analysisModalIsOpen, setAnalysisModalIsOpen] = useState(false);
  const { currentTeam } = useContext(TeamsContext);

  const { addListener, removeListener, isInitialized: isSocketInitialized, joinChannels } = useSocketContext();

  useEffect(() => {
    if(isSocketInitialized) {
      if (isRollUp) {
        joinChannels([`document-request-user-file/${fileId}`]);
      } else {
        joinChannels([`document-request-user-file-document/${documentId}`]);
      }
    }
  }, [joinChannels, isSocketInitialized, isRollUp, fileId, documentId]);

  useEffect(() => {
    const handleNewStatus = async (_data: unknown): Promise<void> => {
      if (isRollUp && getDocumentFileValidation && fileId) {
        getDocumentFileValidation({fileId});
      } else if (getDocumentValidation && documentId) {
        getDocumentValidation({ documentId });
      }
    };
    const listenerId = addListener({
      fn: handleNewStatus,
      messageType: SocketMessageType.STATUS_CHANGE,
    });
    return () => {
      removeListener(listenerId);
    };
  }, [addListener, removeListener, isRollUp, getDocumentValidation, getDocumentFileValidation, documentId, fileId]);

  if (!status) return null;

  return (
    <Buttons
      isWrapper
      onClick={() => {
        if (!documentAnalysis) {
          return;
        }
        if (!currentTeam) {
          return;
        }

        setAnalysisModalIsOpen(true);
      }}
      className={s.validationStatusButton}
    >
      <ValidationStatus status={status} approvedBy={approvedBy || ''} />
      {analysisModalIsOpen && documentAnalysis && (
        <DocumentAnalysisModal
          status={status}
          documentAnalysis={documentAnalysis}
          approvedBy={approvedBy}
          isRollUp={isRollUp}
          onClose={() => {
            setAnalysisModalIsOpen(false);
          }}
          onClick={onClick}
          documentId={documentId}
          fileId={fileId}
          documentAnalysisRaw={documentAnalysisRaw}
        />
      )}
    </Buttons>
  );
};

export default ValidationStatusButton;
