import { useContext } from 'react';
import SocketContext, { SocketContextType } from './SocketContext';

type ReturnValue = Pick<SocketContextType, 'addListener' | 'removeListener' | 'isInitialized' | 'joinChannels'>;

const useSocketContext = (): ReturnValue => {
  const { addListener, isInitialized, joinChannels, removeListener } = useContext(SocketContext);
  return { addListener, isInitialized, joinChannels, removeListener };
};

export default useSocketContext;
